import FarmMap from '../images/FarmMap.png'
import Faq from './Faq.js'
import BerryCrops from './BerryCrops.js'
import VeggieCrops from './VeggieCrops.js'



export default function VisitCrops() {
  return (
      <div className="bg-white py-24 ">
          <div className="mx-auto max-w-7xl px-6 text-center mb-20 lg:px-8 ">
          <p className="mt-2 lg:text-3xl text-2xl text-blue font-semibold leading-6 mb-12">What We Grow</p>
          <BerryCrops class="mb-20"/>
          </div>
      </div>
  );
}