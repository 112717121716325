import React, { useState } from "react";

const BerryDetails = ({ selectedBerry }) => {
  return (
    <div className="mt-4 m-auto  ">
      <h2 className="text-xl  font-medium mb-2 text-center">{selectedBerry.title}</h2>
      <p className="mb-4 text-center text-lg"><strong>Season:</strong> {selectedBerry.season}</p>
      <p className="text-gray-300 max-w-4xl text-justify">{selectedBerry.description}</p>
    </div>
  );
};

const BerryButtonsGrid = ({ berries, onSelectBerry }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4">
      {berries.map((berry, index) => (
        <button
          key={index}
          onClick={() => onSelectBerry(index)}
          className=" hover:bg-blue text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
        >
          {berry.title}
        </button>
      ))}
    </div>
  );
};

const BerryContainer = () => {
  const berries = [
    {
      title: "Blueberry",
      season: "Early July - Mid September",
      description: "Blueberries are our pride and joy. Fun Fact: The oldest blueberry plant on our farm dates back to the 1950s, making it nearly 70 years old! Our journey began with these delicious berries, and they remain our foremost specialty.  Blueberries are more than just a treat—they're a superfood! Rich in dietary fiber, vitamin C, vitamin K, manganese, iron, and a host of antioxidants, they offer numerous health benefits.",
      src: "From Wikipedia, the free encyclopedia: "
    },
    {
      title: "Strawberry",
      season: "June & Everbearing",
      description: "Our strawberries are renowned for their unbeatable super sweet  flavor. Grown naturally without any fertilizers, our berries may not always be the largest, but they are always the sweetest! Savor the natural sweetness of our strawberries and taste the difference that comes from growing them with care and commitment to quality.",
    },
    {
      title: "Blackberry",
      season: "July - Late October",
      description: "At Sidhu Farms, blackberries are a crowd-pleaser;  our most popular variety being the Triple Crown. This exceptional, thornless blackberry plant is celebrated for its high yield and resilience.  Fun fact: While many believe there are only 1-2 types of blackberries, there are actually hundreds! Native to the U.S., our blackberries come in a diverse range of varieties, showcasing the true richness of this beloved fruit.",
    },
    {
      title: "Tayberry",
      season: "June - July",
      description: "Introducing the Tayberry at Sidhu Farms—a unique and flavorful berry with origins in Scotland. This exceptional fruit is a cross between a blackberry and a red raspberry, offering a larger, juicier experience compared to the Loganberry, with which it shares a close relationship.  The Tayberry boasts a distinctive sweet-tart flavor and an attractive magenta to purple color, making it perfect for fresh eating, as well as for use in jams, jellies, and pies. It matures later in the summer than blackberries, providing a rich, singular harvest each year."
    },
    {
      title: "Loganberry",
      season: "July - August",
      description: "A fascinating hybrid that combines the best of North American blackberries and European raspberries. Believed to be a wild cross between these two fruits, Loganberries offer a distinctive flavor that blends the tartness of blackberries with the sweetness of raspberries."
    },
    {
      title: "Raspberry",
      season: "Mid June - November",
      description: "Raspberries are our longest season berry variety, delighting our customers with their sweet flavor well into November. We cultivate both the classic red raspberry, known for its velvety softness and rich sweetness, and a unique yellow variety for those seeking something a little different. Enjoy the extended season and diverse flavors of our raspberries, and savor the taste of summer throughout the year at Sidhu Farms."
    },
    {
      title: "Black Cap Raspberry",
      season: "July",
      description: "A distinctive and flavorful addition to our berry collection, this brambly perennial shrub has powder white canes and antioxidant rich berries that transform from red to black as they ripen. Known for their tart yet decadent flavor, Black Cap Raspberries are a true treat for the taste buds. A native raspberry? Yes indeed, and they taste amazing!"
    },
    {
      title: "Marionberry",
      season: "Early July - Mid August (5 weeks)",
      description: "Fun Fact:  While not all blackberries are marionberries, every marionberry is a type of blackberry! Two different but specific types of blackberries crossed together to make this amazing fruit. These berries are known for their oblong shape and are celebrated for their superior sweetness and juiciness.  Marionberries thrive best in the Pacific Northwest. It's one of the few regions in the world that specializes in harvesting and processing this type of berry and the climate perfectly suits their growth. As one of the few regions globally that specializes in Marionberries, we’re proud to offer these high-yield, flavorful berries."
    },
    {
      title: "Gooseberry",
      season: "July - August",
      description: ""
    },
    {
      title: "Boysenberry",
      season: "End of June - Middle of August",
      description: "First of all, the Boysenberry is the best in our opinion! It is a hybrid berry; a cross between a Loganberry, Red Raspberry, Pacific Blackberry and American Dewberry. We believe this combo makes one of the greatest berries in the world"
    },
    {
      title: "Loganberry",
      season: "Month of July",
      description: "A fascinating hybrid that combines the best of North American blackberries and European raspberries. Believed to be a wild cross between these two fruits, Loganberries offer a distinctive flavor that blends the tartness of blackberries with the sweetness of raspberries."
    },
    {
      title: "Gooseberry",
      season: "July - August",
      description: "Our Gooseberries are a standout treat, originating from the American cultivar. These unique berries start out green and ripen to a range of colors including red, yellow, and even white. Their translucent skin reveals a delightful fruit that evolves from tart to sweeter as it ripens.  Gooseberries are often compared to grapes in size and flavor, offering a refreshing taste that’s perfect for various culinary uses."
    },
    {
      title: "Kiwiberry",
      season: "Coming Soon",
      description: "Introducing Kiwi Berries at Sidhu Farms—a delightful mini version of the classic kiwi. These charming berries closely resemble their larger cousin but boast smooth, edible green skin, making them a convenient and flavorful treat. Discover the unique taste and ease of Kiwi Berries, and enjoy a refreshing burst of flavor that's perfect for snacking, salads, or adding a tropical touch to your favorite dishes. Keep an eye out for their arrival and be among the first to experience this tasty gem!"
    },
    {
      title: "Red Currants",
      season: "Coming Soon",
      description: "We proudly offer Red Currants—small, vibrant berries that thrive in northern climates with warm summers and cold winters. These tiny, round to oval berries, averaging 8 to 12 millimeters in diameter, grow in clusters along delicate pale yellow-green stems. Their smooth, glossy red skin encases tender, succulent flesh that ranges from transparent to dark red, with tiny yellow-brown seeds nestled within.  Red Currants are known for their tart flavor, which combines sweet, acidic, and sour notes reminiscent of raspberry, pomegranate, and cranberry. Beyond their delightful taste, these berries are a nutritious superfood with potent medicinal qualities, making them a valuable addition to your diet."
    },
    {
      title: "Black Currants",
      season: "Coming Soon",
      description: "Discover the bold, tart flavor of Black Currants at Sidhu Farms. Grown in clusters on shrubs, Black Currants thrive in northern climates with warm summers and cold winters. Their natural pectin and acidity make them ideal for fresh eating, preserves, and jams. These small but mighty berries are known for their strong, earthy tones and bright, acidic bite with hints of cherry. Rich in vitamin C, iron, and manganese, Black Currants are packed with antioxidants that can bolster your immune system and provide various health benefits, including antimicrobial, anti-inflammatory, antiviral, and antiseptic properties."
    },
  ];

  const [selectedBerryIndex, setSelectedBerryIndex] = useState(0);

  const handleSelectBerry = (index) => {
    setSelectedBerryIndex(index);
  };

  const selectedBerry = berries[selectedBerryIndex];

  return (
    <div className="container lg:max-w-7xl mx-auto px-4">
      <h1 className="text-2xl font-medium mb-8">Our Berries</h1>
      <BerryButtonsGrid berries={berries} onSelectBerry={handleSelectBerry} />
      <hr className="my-8" />
      <BerryDetails class="" selectedBerry={selectedBerry} />
    </div>
  );
};

export default BerryContainer;
