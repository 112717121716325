import VisitHeader from './VisitHeader'
import VisitCrops from './VisitCrops'
import FarmMap from '../images/FarmMap.png'
import photo from '../images/VisitPhoto.jpg'
import natural from '../images/natural.png'
import Natural from '../components/Natural'
import FarmStand from '../components/FarmStand'




export default function VisitPage() {

    return (
        <div>
            <VisitHeader class="h-auto" />

            <div className="mt-6 ">
          <div className="mx-auto max-w-7xl px-6 text-center  lg:px-8 ">


          </div>
          <div class="px-4 lg:px-28 justify-center text-center mb-20 ">

            <div>
<FarmStand />
            </div>


            <Natural />




          
          </div>

      </div>


            <VisitCrops />
      
            
        </div>
    )
}