import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import Natural2 from './Natural2'
import blueberryvine from '../images/blueberryplant.jpg'

const features = [
  {
    name: 'USDA Certified Organic: ',
    description:
      'This prestigious certification guarantees our berries meet the strictest organic standards set by the National Organic Program. This ensures our practices promote ecological balance, conserve biodiversity, and avoid harmful chemicals.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'USDA & WSDA Good Agricultural Practices (GAP):',
    description: 'This voluntary audit program verifies every step of our operation, from harvesting and packing to storage and distribution. This ensures the highest standards of food safety and worker well-being are met. Natural Practices Make a Difference',
    icon: LockClosedIcon,
  },

]

export default function Natural() {
  return (
    <div className="overflow-hidden  rounded-lg pt-12">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-4 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-0">
            <h1 id="" class="mb-5 text-4xl font-light text-blue">Commitment to <span id="friz" class="font-bold">NATURAL</span></h1><dl class="text-pink border-solid border-2">
            </dl>
            <p class="mt-8 leading-7 mb-8" >At Sidhu Farms, we're passionate about cultivating delicious berries using sustainable and natural methods. We believe in fostering a healthy environment for our berries to thrive, from the soil they grow in to the way we manage pests.</p>

            <div className="mx-auto max-w-2xl py-2 lg:mx-0 lg:max-w-lg md:text-left text-center">
              <p className="mt-2 text-2xl font-semibold tracking-tight text-blue ">Naturally Grown, Always</p>
              <p className="mt-6 text-md leading-7 text-gray-600">
              We don't just talk the talk, we walk the walk. All our berries are grown using natural practices, even the plots that aren't certified organic. This means you can be confident you're getting berries free of synthetic fertilizers, pesticides, and genetic engineering.
              </p>
              <div class="border-solid">
              <h3 class="mt-6 font-semibold text-pink text-lg">Certifications we hold dear:</h3>
              <dl className="mt-6 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative ">
                    <dt className="inline font-semibold text-gray-900">
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              </div>
          
            </div>
            
          </div>

          
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden  sm:mx-auto s  lg:mx-0 lg:max-w-none">
              <div
                className="absolute  w-full    opacity-20 "
                aria-hidden="true"
              />
              <div className="mx-auto mx-auto  m-auto justify-center text-center  ">
                <img
                  src={blueberryvine}
                  alt="Product screenshot"
                  width={2432}
                  height={1442}
                  className="  md:max-w-xl max-w-lg justify-center text-center m-auto "
                />
              </div>
            
            </div>
          </div>
        </div>
        <div class="border-2 border-solid rounded-lg border-pink mt-10 "> 
              <h3 id="raleway" class="tracking-wide mt-6 font-semibold text-center text-pink text-2xl">The Difference You Can Taste</h3>
                <p class="py-6  mb-2 text-lg text-center lg:px-10">Our commitment to natural practices goes beyond just avoiding chemicals. It's about creating a healthy ecosystem that produces the most flavorful and nutritious berries possible. By choosing our farm, you're not only getting delicious berries but also supporting sustainable and responsible agriculture.</p>
              </div>
        <Natural2 />
      </div>
    </div>
  )
}
