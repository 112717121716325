const faqs = [
  {
    question: "How long do the berries last?",
    answer: "It depends on the berry variety. Generally, raspberries and strawberries are the most delicate and will last 3-4 days, blackberries 4-5 days and blueberries over a week."
  },
  {
    question: "How often do you harvest berries?",
    answer: "Everyday! We pick berries early in the morning, and the berries we bring to the market are always picked fresh that same day."
  },
  {
    question: "Are the berries hand picked?",
    answer: "Yes! We always hand pick all of our berries."
  },
  {
    question: "Should the berries go into the fridge?",
    answer: "Yes. Always store your berries in the fridge for the longest freshness."
  },
  {
    question: "Should I wash my berries right away?",
    answer: "That depends on how quickly you plan to consume them. Check the guide below for further instructions."
  }
];

  
  export default function Faq() {
    return (
      <div className=" border-solid border-2 border-pink rounded-xl mb-14">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:pt-12 lg:px-8 lg:py-10 ">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
          <h2 className="text-2xl font-medium  mb-6 text-blue">Berry Care FAQ’s</h2>
          <p className="mb-12 ">
        Please see storage tips below for more detailed information on how best to store and care for your berries
      </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-pink">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
    )
  }
  