
// Example data for the image and text
const sectionData = {
  image: 'https://i.imgur.com/jkLXqho.png',
  title: 'Visit us at the Farmers Market',
  description: '  Visit us at the farmers markets for the freshest berries possible! We hand pick the berries each morning before heading to a neighborhood market. Make sure to come by every week to try all of our 60+ varieties of berries just as they are ready to harvest; new varieties are available daily.',
  descriptiontwo: '         We’re at farmers markets year round but most open in late spring & summer, so check the links below for dates and times of operation of a market near you. We’ll see you there!'
};

const MarketGallery = () => {
  return (
<div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row items-center  rounded-lg  overflow-hidden">
        <div className="w-full md:w-1/2  md:h-auto">
          <img
            src={sectionData.image}
            alt="Farmers Market"
            className="w-full h-50  object-cover rounded-lg ml4"
          />
        </div>
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <h2 className="text-3xl font-medium mb-4 mt-4 lg:mt-0 text-blue">{sectionData.title}</h2>
          <p className=" text-lg ">{sectionData.description}</p>
          <p className=" text-lg mt-6 ">{sectionData.descriptiontwo}</p>

        </div>
      </div>
    </div>
  );
};


export default MarketGallery;
