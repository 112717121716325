import photo1 from '../images/photo1.jpg'
import family from '../images/family.JPG'
import photo3 from '../images/photo3.jpg'



export default function PhotoGal() {
    
    return (
        <div>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-0 m-auto justify-center items-center h-auto  mt-16 space-between">
            <div class="h-auto"><img class="h-auto m-auto" src={photo1} /></div>
            <div class="h-auto h-fit"><img class="h-auto m-auto" src="https://i.imgur.com/HJkjx9e.jpg" /></div>
        
            </div>
        </div>
    )
}