import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";
import PumpkinPage from './components/PumpkinPage';



import './App.css';
import Home from './components/Home'
import About from './components/About'
import Visit from './components/VisitPage';
import Store from './components/Store'
import Market from './components/Market'
import BerryCare from './components/BerryCare';
import Contact from './components/Contact'
import Nav2 from './components/Nav2'
import Footer from './components/Footer'


export default function App() {
  return (
    <div className="">

      
          <header>
              <Router>
                <Nav2 />
                <Routes>
                  <Route path='/' element={<Home/>} />
                  <Route path='/about' element={<About/>} />
                  <Route path='/visit-the-farm' element={<Visit />} />
                  <Route path='https://www.sidhufarm.org/'/>
                  <Route path='/farmers-markets' element={<Market/>} />
                  <Route path='/berry-care+' element={<BerryCare/>} />
                  <Route path='/pumpkin-patch' element={<PumpkinPage />} />

                  <Route path='/contact' element={<Contact/>} />

                </Routes>
                <Footer />

            </Router>
    </header>
    </div>
  )
}

