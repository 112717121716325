import b from '../images/b.PNG'
import timeline from '../images/Timelinev3.png'
import mobiletimeline from '../images/MobileTimeline.png'
import family from '../images/family.JPG'




export default function About1() {
    return (
      <div className="bg-[#ffffff] py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className=" grid max-w-xl grid-cols-1 gap-12  lg:text-left text-center text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
              <div>
              <p className="text-2xl mb-10 font-semibold leading-7 tracking-wide text-pink">Rooted in family and and tradition</p>

                <p>
                The Sidhu Family’s ancestral homeland is Punjab, India. Founder, Owner, Operator and Father, Chet Sidhu, moved to the United States in the early 1980’s. Chet brought along the rich legacy of generations of farming in India going back further than they can trace. This thriving tradition continues today with the family-run farm in Puyallup, WA, where the Sidhus have been farming since 1997. Beginning with an existing plot of land growing natural blueberries, the Sidhus began selling their fruits at the famous Pike Place Market and expanded to markets throughout the region, becoming known in the community for producing exceptional berries, practicing the most natural farming ways and their family-centered operations. 
                <br></br><br></br>
                </p>

                <p class="mt-">
                The farm is nestled in the heart of the Puyallup Valley, appearing to be directly at the base of stunning Mt. Rainier. Sunsets on Sidhu Farms are breathtakingly beautiful. Today, the farm is still run by Chet and his three sons, Kamal, Sunny and Sarge, along with their wives and extended family. As the family grows, so does the operation. 
                </p>
                <img class='lg:h-40 mt- justify-center m-auto' src={b} />


        
              </div>
              <div class="">
              <div class=""><img class="" src={family} /></div>


              <p className="mt-8">
                Along with their blueberries, raspberries, blackberries, strawberries, the Sidhus make award winning jam in over a dozen flavors, made with a lower sugar content as the depth of sweetness of flavor in their berries makes the product exceptional without adding excess sugar. The Sidhus produce delectable golden honey from their multiple berry fields. Although they specialize in berries, they also grow row crop vegetables, pumpkins and rhubarb. 

                </p>
              </div>
            </div>
     
          </div>
          
          <p className="text-2xl font-semibold leading-7 text-center justify-center tracking-wide text-pink mt-14">Timeline</p>

          <img id="lgtimeline" class=' justify-center m-auto' src={timeline} />
            <img id="mobiletimeline" class=' mt-16 justify-center m-auto' src={mobiletimeline} />
          </div>





        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div class="lg:mx-40 mx-0"><img class="mt-20 lg:mb-20 mb-10 " src="https://i.imgur.com/uq9xTIG.jpg" alt="Kamal & Sarge Sidhu" /></div>


            <div className="mt-6 lg:text-left text-center lg:mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>
                The farmstand store is open 7 days a week year round selling all of their freshly harvested produce, jams and honey, as well as groceries and Indian foods and spices. The Sidhus also deliver to retail customers and produce a large portion of the region's wholesale berries and rhubarb and participate in over 30 farmers markets each week. The Sidhu Farms signature sweet nature is a great joy felt every time a long time loyal or brand new customer meets them at a farmers market to buy berries, jam, honey and veggies.
                </p>
                <p className="mt-8">
                As the farm and family expanded, so did the development in the region. Oftentimes, their additional leased land plots would get sold and paved over. The rich farmland in the area was steadily dwindling. The Sidhu's commitment to land stewardship through natural farming runs as deeply as their farming roots. Year over year, the Sidhus remain committed to farming without pesticides, chemicals or synthetic fertilizers. Not only are the berries tastier and healthier for consumption, the soil is enriched with proper nutrients, and the soil and environmental health at every level is optimized. Thankfully, with the help of environmental conservationist nonprofit, Forterra, the Sidhus were able to acquire 80 acres of land from a retiring berry farmer in 2015. 
                </p>
              </div>
              <div>
                <p>
                This land is protected and allowable solely for farming for perpetuity. It sits along a glacial river that is used in the watering of the fields. This land, in the dutiful hands of the Sidhu family, is a great win for the entire local food and greater environmental ecosystem. 
                </p>
                
                <p className="mt-8">
                The farm is more lively than ever since the addition of a fall pumpkin patch & corn maze and also the recent additions of the latest generation of Sidhu kiddos and babies.  The fun expands every year! Kids and grown ups visit the pumpkin patch for activities like hay rides, bouncy houses, amazing food, a thrilling adventure through a corn maze, and of course, the joy of selecting their very own special pumpkin to take home to carve. A very family friendly farm indeed!

                </p>
              </div>

            </div>
     
          </div>

                    </div>

  
      </div>
    )
  }
  