import photo1 from '../images/photo1.jpg'



  const stats = [
    { name: 'Acres', value: '120+' },
    { name: 'Different types of Berries', value: '60+' },
    { name: 'Different types of Veggies', value: '13' },

  ]
  
  export default function Visit3() {
    return (
      <div id="visitbg1" className="relative isolate  ">
       
  

   
        <div className="mx-auto  max-w-7xl px-6 lg:px-8 text-center ">
        <div className="mx-auto max-w-2xl py-32 sm:py-30">
        <div class="bg-yellow px-3 py-2.5 rounded-full md:text-lg text-sm font-medium text-blue"><p> <span class="font-bold">Visit our Farm Stand!</span> Open Everyday <span class="font-bold">9:00am to 7:00pm</span> </p></div>

          <div className="text-center">
           
              <h1 id="friz" class="lg:text-7xl text-6xl font-bold text-white mt-20 tracking-wide">VISIT THE FARM</h1>
              <dl className="mt-10 grid grid-cols-1 gap-8  grid-cols-1 -mb-14 md:grid-cols-3 lg:grid-cols-3">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-white">{stat.name}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
                </div>
              ))}
            </dl>
          
          </div>
          </div>
   
          
            
          </div>
          
        </div>
        
    )
  }
  