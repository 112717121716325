import Faq from './Faq'

export default function BerryCare() {



    return (
        <>
        <div   id="berrycarebg" className=" object-fit relative isolate  ">

        <div  className="mx-auto max-w-2xl py-28 sm:py-30">
          
        <div className="text-center">
         
            <h1 id="friz" class="text-7xl font-bold  mt-16 tracking-wide text-white">BERRY CARE+</h1>
        
        
        </div>
        </div>


        
        </div>
     <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8 lg:text-left text-center justify-center m-auto">
     
  
      <h1 className="text-4xl font-semibold text-center mb-8 mt-8 tracking- text-blue">
        Berry Care<span class="text-bold text-pink">+</span> Pro Tips for Optimal Freshness
      </h1>
      <h3 id="raleway" class="text-2xl font-normal px-4 text-center mb-12 text-gray-200 text-pink">How to care for your berries when you get them home. <br></br><br></br><span class="font-semibold">Washing, Storing, Freezing & Enjoying!</span></h3>
      <p className="text-lg mb-8 lg:text-left text-justify px-4">

        Our berries are picked at the absolute peak of flavor and ripeness and we want to assure you that same level of perfection & delight when you enjoy them. We created this Berry Care<span class="font-bold text-pink">+</span> guide for you. For instance, not all berry varieties keep the same so please read below on the best ways to enjoy nature’s very best berries!
      </p>


      <Faq />


<div class="lg:text-left text-center lg:px-0 px-8">


      <h2 className="text-xl font-medium mb-4 text-blue">Berry Care<span class="text-bold text-pink">+</span> Storage Tips</h2>
      <p className="mb-7 lg:mb-0 ">
        How long the berries will last depends on the variety, the growing conditions and how well you care for them. For example, berries harvested when it’s 90 degrees F and above, will not last as long as berries harvested during lower temps.
      </p>
      
      <div class=" grid lg:grid-cols-2 grid-cols-1 vertical-align justify ">
      <div>
      <ul className=" lg:ml-6 lg:mb-16 lg:pt-10">
        <li className="mb-2">Raspberries: 3<span class="text-blue">-</span>4 days</li>
        <li className="mb-2">Strawberries: 3<span class="text-blue">-</span>4 days</li>
        <li className="mb-2">Blueberries: Over a week</li>
        <li className="mb-2">Blackberries: 4<span class="text-blue">-</span>5 days</li>
        <li className="mb-2">Tayberries: 3<span class="text-blue">-</span>4 days</li>
        <li className="mb-2">Marionberries: 3<span class="text-blue">-</span>4 days</li>
        <li className="mb-2">	Gooseberries: about a week</li>
        <li className="mb-2">Boysenberry: 3<span class="text-blue">-</span>4 days</li>
        <li className="mb-2">Loganberry: 3<span class="text-blue">-</span>4 days</li>      
        </ul>
        </div>
        <div class="p-8">
          <img class="h-auto " src="https://i.imgur.com/KfJG8Wt.png" />
        </div>
        </div>


  

      <h2 className="text-2xl font-medium text-blue lg:mt-0 mt-8 mb-6">Additional Berry Care Tips</h2>

      <p className="mb-6">
        <span class="text-pink font-semibold">+</span> You can extend the life of your berries with these Berry Care<span class="text-pink font-bold">+</span> Tips 
      </p>

      <h3 className="text-xl font-semibold mb-4">How to store your fresh berries when you get them home</h3>
      
      <p className="mb-4 bg-pink text-white  p-5 rounded-lg mb-6">
      <span class="font-bold">If you plan to eat them in the next day or two:</span><br></br> <br></br>Just keep them in the green cartons and put them directly into the fridge without washing. Wash the portion you wish to eat just before consuming.
      </p>
      <p className="mb-4 text-blue font-semibold mb-6">
        To wash, rinse with water or a water/vinegar solution
      </p>

      
      <p className="mb-4 mb-4 bg-pink text-white  p-5 rounded-lg mb-6">
        <span class="font-bold">If you plan to keep your berries as long as possible:</span><br></br> <br></br>Don’t wash them just yet! ONLY Wash them when you are going to use them! <br></br><br></br>Take them out of the green cartons. Use a tupperware or glass container with a lid or cover (sealed is best) and lay them out in ONE LAYER, so there is less weight on each berry.<br></br> <br></br>Place a dry paper towel over them inside the container. Important: when it gets damp, swap out the paper towel for a new dry one. (You can also put a paper towel under the berries but it is harder to swap out.) <br></br><br></br> Make sure they stay as dry as possible! Moisture and water are the biggest killers of fresh berries, especially when they are grown naturally & without pesticides. Wash each portion just before eating and enjoy!
      </p>

      <p className="mb-6">
        <span class="text-pink font-bold">What if you can’t eat a whole flat of berries while they are fresh?</span><br></br> <br></br>Just freeze them!! Enjoy the best in-season berries in your future smoothies, breakfast cereals, yogurts, overnight oats, pancakes, waffles, jams and all your favorite recipes!
      </p>




      <h3 className=" font-semibold mb-2 text-xl">How to prepare and freeze your berries</h3>

      <ol class="lg:ml-6 ml-0 list-decimal text-left mb-20 mt-4 lg:max-w-sm" id="raleway">
        <li class="mb-1">Give your berries a wash (rinse with water or use a water/vinegar solution)</li>
        <li class="mb-1">Pat them dry with a clean towel or paper towels</li>
        <li class="mb-1">Place the berries on a cookie sheet in one layer so they are not sticking together or clumped</li>
        <li class="mb-1">Place the sheet into the freezer so it is flat and level</li>
        <li class="mb-1">When they are nice and frozen, take the baking sheet out and immediately place berries into sealable plastic freezer bags</li>
      </ol>
      </div>











    </div>



        </>
    )
}