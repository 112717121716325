import timeline from '../images/Timelinev3.png'
import mobiletimeline from '../images/MobileTimeline.png'





export default function AboutFeature1() {
    return (
      <div className="relative bg-[#FFFFFF] px-6 pb-20 lg:pt-20 pt-16 lg:px-8 lg:pb-22 ">
        <div className="absolute inset-0">
          <div className="" />
        </div>
        <div className="relative mx-auto max-w-6xl">
          <div className="text-center">
            <h2 className="lg:text-4xl tracking-normal text-blue text-3xl">The <span id="friz" className="text-blue font-semibold">SIDHU</span> Timeline</h2>

            <img id="lgtimeline" class=' mt-5  justify-center m-auto' src={timeline} />
            <img id="mobiletimeline" class=' mt-12 text-center justify-center m-auto ' src={mobiletimeline} />


          </div>

          <div class='flex mt-10  mb-10 justify-center m-auto items-center'>
          <button
            id='Poppins'
            type="button"
            class="rounded-full m-auto  bg-blue hover:bg-pink shadow-sm shadow-gray-dark  backdrop-blur mt-4  px-7 pb-[8px] pt-[10px] hover: text-md font-semibold  uppercase tracking-wide text-white  transition duration-150  "
            data-twe-ripple-init
            data-twe-ripple-color="light"><a href="/about" target="_blank">
            Read the Sidhu Story</a>
          </button>
          </div>
        </div>
  
      </div>
      
    )
  }
  