import MarketGallery from "./MarketGallery"
import MarketSchedule from "./MarketSchedule"


export default function Market() {
  return (
    <div className=" ">
      <div className="">


      <div id="marketbg" className="relative isolate overflow-hidden ">

                    <div
                      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                      aria-hidden="true"
                    >
                      <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                          clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                      />
                    </div>
                    <div className="mx-auto max-w-2xl py-28 sm:py-30">
          
                    <div className="text-center">
                     
                        <h1 id="friz" class="lg:text-7xl text-6xl font-bold text-white mt-16 tracking-wide">FARMERS<br></br> MARKETS</h1>
                        <p className="mt-4 lg:text-xl sm: text-lg leading-6 text-white">
                        Western Washington
                        </p>
                    
                    </div>
                    </div>
                  <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                  >
                    <div
                      className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                      style={{
                        clipPath:
                          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                      }}
                    />
                  </div>
              </div>
        </div>
        <div class="mx-auto max-w-7xl px-6 lg:px-8 md:px-20 px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"></h2>
        

        <div class="mt-12">
        <MarketGallery />
        </div>

    
          
        <p className="mt-10 lg:text-3xl text-2xl leading-6 font-medium text-blue mb-10 text-center ">
                        <span class="font-light text-yellow">-</span> Farmers Market Schedule <span class="font-light text-yellow">-</span>
                        </p>
     

        

        
        </div>
              <MarketSchedule />
          </div>
  )
}
