import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Integrated Pest Management (IPM):',
    description:
      'This approach combines natural methods like allowing beneficial insects and hand-hoeing with minimal, targeted interventions to keep pests in check.',
    href: '#',
    icon: InboxIcon,
  },
  {
    name: 'Promoting Healthy Soil:',
    description:
      'We protect our soil and nurture our crops without using any synthetic fertilizers and without saturating the soil with harmful chemicals. Those practices create a cycle of mineral depletion and synthetics dependence and resistant pests. We believe in creating perfect soil ph balance by being in harmony with nature. This maintains a mineral rich and nutrient dense environment for our berries to flourish.',
    href: '#',
    icon: UsersIcon,
  },
  {
    name: 'Hand-Hoeing vs. Herbicides:',
    description:
      'We believe in keeping our fields weed-free without using herbicides, ensuring the safety of our workers and the environment.',
    href: '#',
    icon: TrashIcon,
  },
  {
    name: 'Cultivation with Care:',
    description:
      'We utilize a combination of field cultivation with tractors and eco weeder attachments to make weeding easier on workers. Then employing hand-hoeing methods for precise weed control closer to the roots, minimizing any potential damage to the berries and eliminating the need for herbicides altogether.',
    href: '#',
    icon: TrashIcon,
  },
]

export default function Natural2() {
  return (
    <div className=" py-10 mt-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto  text-center m-auto justify-center  lg:mx-0">
          <h2 id="raleway" className="text-3xl text-center m-auto  justify-center font-semibold  text-blue sm:text-4xl">Our Practices</h2>
       
        </div>
        <div className="mx-auto justify-center text-center m-auto max-w-2xl lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 lg:gap-y-16 m-auto text-center lg:max-w-none lg:grid-cols-4">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-md font-semibold text-pink leading-7 ">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500 text-pink">
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
