import React from 'react';

const markets = {
  Monday: [
    { name: "Sidhu Farm Stand", link: "" },
  ],
  Tuesday: [
    { name: "The Village - Puyallup", link: "https://www.facebook.com/TheVillageFarmersMarket/" },
    { name: "Renton", link: "https://www.rentonfarmersmarket.com" },
    { name: "Lakewood Tacoma", link: "https://cityoflakewood.us/farmersmarket/" },

  ],
  Wednesday: [
    { name: "Wallingford", link: "http://www.sfmamarkets.com/visit-wallingford-farmers-market" },
    { name: "Kirkland", link: "https://kirklanddowntown.org/events/kirkland-market/" },
    { name: "Steilacoom", link: "https://townofsteilacoom.org/267/Farmers-Market" },
    { name: "Sammamish", link: "https://www.sammamishfarmersmarket.org/" },

  ],
  Thursday: [
    { name: "Queen Anne", link: "https://qafm.org" },
    { name: "SLU - Pike Express", link: "https://www.pikeplacemarket.org/market-programs-events/farmers-markets/" },
    { name: "Bellevue", link: "https://bellevuefarmersmarket.org" },
    { name: "Burien", link: "https://www.burienwa.gov/news_events/featured_events/farmers_market" },
    { name: "Snohomish", link: "https://www.snohomishfarmersmarket.org" },
    { name: "North Bend", link: "https://www.facebook.com/NorthBendFarmersMarket/" },
    { name: "Tacoma Farmers Market", link: "https://tacomafarmersmarket.com/broadway-farmers-market/" },
    { name: "Dupont", link: "https://www.dupontwa.gov/566/Farmers-Market" },

  ],
  Friday: [
    { name: "Madrona", link: "http://www.sfmamarkets.com/madrona-farmers-market/" },
    { name: "Orting", link: "https://www.ortingvalleyfarmersmarket.com" },
    { name: "First Hill - Pike Expess", link: "https://www.pikeplacemarket.org/market-programs-events/farmers-markets/" },
    { name: "Juanita", link: "https://www.kirklandwa.gov/Government/Departments/Parks-and-Community-Services/Special-Event-Services/Juanita-Friday-Market" },

  ],
  Saturday: [
    { name: "Tacoma Proctor", link: "https://proctorfarmersmarket.com" },
    { name: "Puyallup", link: "https://www.puyallupmainstreet.com/puyallup-farmers-market/" },
    { name: "Issaquah", link: "https://www.issaquahwa.gov/778/Farmers-Market" },
    { name: "Des Moines", link: "https://dmfm.org" },
    { name: "Federal Way", link: "https://federalwayfarmersmarket.com" },
    { name: "Maple Valley", link: "https://www.maplevalleyfarmersmarket.com" },
    { name: "Shoreline", link: "https://shorelinefarmersmarket.org/home" },


  ],
  Sunday: [
    { name: "Ballard", link: "http://www.sfmamarkets.com/visit-ballard-farmers-market/" },
    { name: "Lake Forest Park", link: "https://www.thirdplacecommons.org/farmers-market/directions-to-the-lake-forest-park-farmers-market/" },
    { name: "Auburn", link: "https://www.auburnwa.gov/cms/one.aspx?portalId=11470638&pageId=12529317" },
    { name: "Tacoma Point Rustin", link: "https://www.pointruston.com/retail/farmersmarket/" },
    { name: "Mercer Island", link: "https://www.mifarmersmarket.org" },
    { name: "Tumwater", link: "https://www.thetumwaterfarmersmarket.com/" },


  ],
};

const MarketSchedule = () => {
  return (
    <div className="container mx-auto p-4 mb-16 lg:px-0 md:px-0  lg:text-left text-center">
      <div className="grid grid-cols-1 lg:grid-cols-7 justify-center m-auto  max-w-fit	 gap-4">
        {Object.keys(markets).map((day) => (
          <div key={day} className="bg-white shadow-md rounded-lg px-16 lg:px-4 px- p-4">
            <h2 className="text-lg font-medium text-blue mb-3 ">{day}</h2>
            {markets[day].length > 0 ? (
              <ul>
                {markets[day].map((market, index) => (
                  <li key={index} className="mb-3">
                    <a
                      href={market.link}
                      className="text-blue-500 hover:underline hover:text-pink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {market.name}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No markets available</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketSchedule;
