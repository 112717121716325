import s9 from '../images/contactimage.png'
import ContactForm from './ContactForm'

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'The Sidhu Story', href: '/about' },
    { name: 'Our Farm', href: '/visit-the-farm' },
    { name: 'Shop', href: '/shop' },
    { name: 'Farmers Markets', href: '/farmers-markets' },
    { name: 'Berry Care+', href: '/berry-care+' },
    { name: 'Contact', href: '/contact' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/SidhuFarmsBerries/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/sidhufarms/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    }
  ]
}

export default function Contact() {
  return (
    <div className="relative bg-[#ffffff]">
      <div   id="contactbg" className=" object-fit relative isolate">

        <div  className="mx-auto max-w-2xl py-28 sm:py-30">
            
          <div className="text-center">
          
              <h1 id="friz" class="md:text-7xl text-5xl font-bold  mt-16 tracking-wide text-white">CONTACT US</h1>


          </div>
          </div>



          </div>

      
      
      <div className="pb-24 pt-4 sm:pb-32  lg:mx-auto justify-center text-center m-auto lg:max-w-7xl  lg:pt-0">
        
        <div className=" grid md:grid-cols-2 gap-x-8 grid-cols-1 px-6 lg:px-8 lg:mt-10">
          <div>
              <ul class="py-4 mt-12">
                <li id="" class="py-1"><a id="poppins" class=" hover:text-blue text-xl font-medium text-blue" href='tel:2536513755'>Phone: <span class="hover:text-pink">+1 253-651-3755</span></a></li>
                <li class="py-1"><a class=" hover:text-blue text-xl font-medium text-blue" href='mailto:sidhufarms@gmail.com'>Email: <span class="hover:text-pink">sidhufarms@gmail.com</span></a></li>

                 
                </ul>
                


                <div className="mt-10 flex justify-center mb-12 space-x-10">
                    {navigation.social.map((item) => (
                      <a key={item.name} href={item.href} className="text-pink hover:text-blue">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </a>
                    ))}
                </div>

                <ContactForm />
                </div>

                <div class="lg:px-0 h-full">

                <iframe class="    mt-4 justify-center lg:h-full h-96 w-full lg:h-auto m-auto mb-10 " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.4376622556333!2d-122.23090628833748!3d47.16886697103271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490fb9e7ae33f3d%3A0xea374a862608b939!2s15018%2096th%20St%20E%2C%20Puyallup%2C%20WA%2098372!5e0!3m2!1sen!2sus!4v1715104224204!5m2!1sen!2sus"    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>


</div>

</div>




<div class="  mt-10  ">
        <iframe src='https://245e53f84de641d29da6869789d90b57.elf.site' width='100%' height='1000' frameborder='0'></iframe>

        </div>

        
      </div>
  )
}
